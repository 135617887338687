import axios from '@axios'
import route from '@/router'

const urlPath = '/atividades'
export default {
  namespaced: true,
  state: {
    paginacao: {},
    isFirstLoad: true,
    modosExibicao: [
      {
        value: 'Em_Desenvolvimento',
        text: 'Em Desenvolvimento',
        filtrado: 0,
        total: 0,
      },
      {
        value: 'Em_Aprovação',
        text: 'Em Aprovação',
        filtrado: 0,
        total: 0,
      },
      {
        value: 'Reprovadas',
        text: 'Reprovadas',
        filtrado: 0,
        total: 0,
      },
      {
        value: 'Concluídas',
        text: 'Concluídas',
        filtrado: 0,
        total: 0,
      },
      {
        value: 'Todas',
        text: 'Todas',
        filtrado: 0,
        total: 0,
      },
    ],
  },
  getters: {},
  mutations: {
    alterarModoExibicao(state, payload) {
      const filtroAtual = route.currentRoute.params.filter

      state.modosExibicao.forEach(filter => {

        if (payload[filter.text]) {
          filter.filtrado = payload[filter.text].filtrado
          filter.total = payload[filter.text].total

        }
      })
    },
    setPaginacao(state, payload) {
      state.paginacao = payload
    },

  },
  actions: {
    fetchTasks(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(urlPath, { params: payload })
          .then(response => {
            ctx.commit('alterarModoExibicao', response.data)
            ctx.commit('setPaginacao', response.data.meta)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/todo/tasks', { task: taskData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTask(ctx, {
      action,
      atividadeId,
      comentario,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${urlPath}/${atividadeId}`, {
            action,
            comentario,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateMassTasks(ctx, listaAtividades) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${urlPath}/mass-update`, listaAtividades)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/todo/tasks/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
